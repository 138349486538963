export default class MenuComponent {
    constructor() {
        this.showSubmenu()
    }

    static #mainEntries = $('.menu__main-menu > .menu__item[data-cx-menu]')
    static #subEntries = $('.menu__submenus > .menu__submenus__items')
    static #mobileMainArrows = $('.js-main-entry-arrow')
    static #mobileSubArrows = $('.js-sub-entry-arrow')
    static #submenus = $('.js-submenus')

    static #getSubEntries(titleSlugged) {
        const $selector = $(
            `.menu__submenus .menu__submenus__items[data-cx-submenu="${titleSlugged}"]`,
        )
        return $selector.length ? $selector : false
    }

    showSubmenu() {
        if (window.matchMedia('(min-width: 1200.1px)').matches) {
            MenuComponent.#mainEntries.on('mouseover', function () {
                const titleSlugged =
                    'undefined' !== typeof $(this).data('cx-menu') ? $(this).data('cx-menu') : false
                MenuComponent.#mainEntries.removeClass('is-active')
                $(this).addClass('is-active')

                if (MenuComponent.#subEntries.length && false !== titleSlugged) {
                    MenuComponent.#subEntries.removeClass('is-active')
                    MenuComponent.#getSubEntries(titleSlugged).addClass('is-active')
                }
            })
        } else {
            MenuComponent.#subEntries.each((index, el) => {
                setTimeout(() => {
                    MenuComponent.#mainEntries.removeClass('is-active')
                    MenuComponent.#subEntries.removeClass('is-active')
                }, 200)
            })

            MenuComponent.#mobileMainArrows.on('click', function () {
                const titleSlugged =
                    'undefined' !== typeof $(this).parent().data('cx-menu')
                        ? $(this).parent().data('cx-menu')
                        : false
                if (MenuComponent.#subEntries.length && false !== titleSlugged) {
                    MenuComponent.#submenus.addClass('is-active')
                    console.log(MenuComponent.#getSubEntries(titleSlugged))
                    MenuComponent.#getSubEntries(titleSlugged).addClass('is-active')
                }
            })

            MenuComponent.#mobileSubArrows.on('click', () => {
                MenuComponent.#subEntries.removeClass('is-active')
                MenuComponent.#submenus.removeClass('is-active')
            })
        }

        const titleSlugged =
            'undefined' !== typeof MenuComponent.#mainEntries.data('cx-menu')
                ? MenuComponent.#mainEntries.data('cx-menu')
                : false
        MenuComponent.#getSubEntries(titleSlugged).addClass('is-active')
    }

    static resetMenu() {
        const titleSlugged =
            'undefined' !== typeof MenuComponent.#mainEntries.first().data('cx-menu')
                ? MenuComponent.#mainEntries.first().data('cx-menu')
                : false
        MenuComponent.#mainEntries.removeClass('is-active')
        MenuComponent.#subEntries.removeClass('is-active')
        MenuComponent.#submenus.removeClass('is-active')

        if (window.matchMedia('(min-width: 1200.1px)').matches) {
            MenuComponent.#mainEntries.first().addClass('is-active')
            MenuComponent.#getSubEntries(titleSlugged).addClass('is-active')
        }
    }
}
