export default class FooterMapsComponent {
    constructor() {
        this.mapSwitcher()
    }

    static mapSwitcherButtonsSelector = $('footer.footer .js-footer-map-switcher-btn')
    static footerMapsSelector = $('footer.footer .js-footer-map-wrapper')

    mapSwitcher() {
        FooterMapsComponent.mapSwitcherButtonsSelector.on('click', function () {
            const mapName = $(this).data('cx-footer-map-switcher')

            FooterMapsComponent.footerMapsSelector.removeClass('is-active')
            FooterMapsComponent.mapSwitcherButtonsSelector.removeClass('is-active')
            $(this).addClass('is-active')
            $(`footer.footer .js-footer-map-wrapper[data-cx-footer-map="${mapName}"]`).addClass(
                'is-active',
            )
        })
    }
}
