/*
 * Created by IRIS Interactive
 * User : IRIS Interactive
 */

import GtranslateAction from './actions/gtranslate.action'
import BannerComponent from './components/banner.component'
import BookingForm from './components/booking-form.component'
import FooterMapsComponent from './components/footer-maps.component'
import MenuComponent from './components/menu.component'
import NavbarComponent from './components/navbar.component'

document.addEventListener('DOMContentLoaded', () => {
    //Component
    new BannerComponent()
    new NavbarComponent()
    new GtranslateAction()
    new MenuComponent()
    new FooterMapsComponent()
    new BookingForm()

    //Front page only
    if ($('.home').length > 0) {
        import(
            '@scripts/services/home.service' /* webpackChunkName: "scripts/home.service" */
        ).then(({ default: HomeService }) => {
            new HomeService()
        })
    }

    //Fullscreen template only
    if ($('.fullscreen').length > 0) {
        import(
            '@scripts/services/layout.service' /* webpackChunkName: "scripts/layout.service" */
        ).then(({ default: LayoutService }) => {
            new LayoutService()
        })
    }
})
