import { DateTime, LockPlugin, RangePlugin, easepick } from '@easepick/bundle'

export default class BookingForm {
    constructor() {
        this.bookingModal()
        this.bookingForm()
        this.setDateStart()
        this.setDateEnd()
        BookingForm.bookingFormActions()
    }

    static searchParams = 'recherche/marketplace'
    static bookingModalSelector = $('.js-customer-booking-engine-modal')
    static bookingFormSelector = $('#form-hebergement')
    static bookingFormAccommodationSelector = $('#hebergement_type')
    static bookingFormAdultsSelector = $('#marketplace_adults_select')
    static bookingFormChildrenSelector = $('#marketplace_children_select')
    static dateStart = ''
    static dateEnd = ''
    static adultsQty = 1
    static childrenQty = 0
    static accommodationType = BookingForm.bookingFormSelector.attr('action')
    static actionUrl =
        `${BookingForm.accommodationType}${BookingForm.searchParams}/${BookingForm.dateStart}/${BookingForm.dateEnd}/${BookingForm.adultsQty}/${BookingForm.childrenQty}`

    static getCurrentDate() {
        const todayDate = new Date()
        return `${todayDate.getFullYear()}-${todayDate.getMonth() + 1}-${todayDate.getDate()}`
    }

    setDateStart() {
        BookingForm.dateStart = BookingForm.getCurrentDate()
    }

    setDateEnd() {
        BookingForm.dateEnd = BookingForm.getCurrentDate()
    }

    // For display / hide booking modal
    bookingModal() {
        const $body = $('body')
        const $bookingButton = $('.js-customer-booking-engine-button')
        const $bookingModal = $('.js-customer-booking-engine-modal')
        const $bookingModalClose = $('.js-customer-booking-engine-modal-close')

        $bookingButton.on('click', () => {
            $bookingModal.addClass('is-active')
            $body.css({
                overflow: 'hidden',
            })

            //Close ecobar when booking is open
            if ($('.navbar--ontop').length) {
                $('.eco-bar').addClass('eco-bar--hidden')
            }
        })

        $bookingModalClose.on('click', () => {
            $bookingModal.removeClass('is-active')
            $body.css({
                overflow: 'visible',
            })
        })
    }

    // For date picker
    bookingForm() {
        if (BookingForm.bookingModalSelector.length > 0) {
            const dateFormat = 'DD MMM YYYY'
            new easepick.create({
                element: document.getElementById('marketplace_search_startdate'),
                lang: 'fr-FR',
                format: dateFormat,
                css: [
                    'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css',
                    IRISCollectionCustomer.styleCustomerEasepick,
                ],
                plugins: [RangePlugin, LockPlugin],
                RangePlugin: {
                    elementEnd: document.getElementById('marketplace_search_enddate'),
                    tooltip: true,
                },
                LockPlugin: {
                    minDate: new Date(),
                },
                setup(picker) {
                    picker.on('select', (e) => {
                        const startDate = e.detail.start.format('YYYY-MM-DD')
                        const endDate = e.detail.end.format('YYYY-MM-DD')
                        const value = {
                            start: startDate,
                            end: endDate,
                        }
                        const valueDateStart = $('#valueDateStart')
                        const valueDateEnd = $('#valueDateEnd')
                        valueDateStart.attr('value', value.start)
                        valueDateEnd.attr('value', value.end)
                        BookingForm.dateStart = value.start
                        BookingForm.dateEnd = value.end
                        BookingForm.actionUrl = `${BookingForm.accommodationType}${BookingForm.searchParams}/${BookingForm.dateStart}/${BookingForm.dateEnd}/${BookingForm.adultsQty}/${BookingForm.childrenQty}`
                        BookingForm.bookingFormSelector.attr('action', BookingForm.actionUrl)
                    })
                },
            })
        }
    }

    static bookingFormActions() {
        BookingForm.actionUrl = `${BookingForm.accommodationType}${BookingForm.searchParams}/${BookingForm.dateStart}/${BookingForm.dateEnd}/${BookingForm.adultsQty}/${BookingForm.childrenQty}`
        BookingForm.bookingFormSelector.attr('action', BookingForm.actionUrl)

        // Action to change the type of accommodation
        if (BookingForm.bookingFormAccommodationSelector.length) {
            BookingForm.bookingFormAccommodationSelector.on('change', function () {
                BookingForm.accommodationType = $(this).val()
                BookingForm.actionUrl = `${BookingForm.accommodationType}${BookingForm.searchParams}/${BookingForm.dateStart}/${BookingForm.dateEnd}/${BookingForm.adultsQty}/${BookingForm.childrenQty}`
                BookingForm.bookingFormSelector.attr('action', BookingForm.actionUrl)
            })
        }

        // Action to change the adults quantity
        if (BookingForm.bookingFormAdultsSelector.length) {
            BookingForm.bookingFormAdultsSelector.on('change', function () {
                BookingForm.adultsQty = $(this).val()
                BookingForm.actionUrl = `${BookingForm.accommodationType}${BookingForm.searchParams}/${BookingForm.dateStart}/${BookingForm.dateEnd}/${BookingForm.adultsQty}/${BookingForm.childrenQty}`
                BookingForm.bookingFormSelector.attr('action', BookingForm.actionUrl)
            })
        }

        // Action to change the adults quantity
        if (BookingForm.bookingFormChildrenSelector.length) {
            BookingForm.bookingFormChildrenSelector.on('change', function () {
                BookingForm.childrenQty = $(this).val()
                BookingForm.actionUrl = `${BookingForm.accommodationType}${BookingForm.searchParams}/${BookingForm.dateStart}/${BookingForm.dateEnd}/${BookingForm.adultsQty}/${BookingForm.childrenQty}`
                BookingForm.bookingFormSelector.attr('action', BookingForm.actionUrl)
            })
        }
    }
}
