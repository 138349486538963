import Swiper from '@iris.interactive/handcook/public/scripts/libraries/swiper-11'

export default class BannerComponent {
    constructor() {
        BannerComponent.init()
    }

    static async init() {
        const $header = $('.header')
        const $bannerSlideHeadings = $('.js-banner-slide-heading')
        const $triggerEl = $('.js-banner-slider-pagination')

        const videoContainer = '[data-banner-video]'
        const swiperContainer = '[data-banner-slider]'

        const slideTitles = []

        $bannerSlideHeadings.each((index, slide) => {
            const slideTitle = $(slide).data('cx-slide-title')
            slideTitles.push(slideTitle)
        })

        // Show banner video only on desktop
        if ($(videoContainer).length > 0 && window.matchMedia('(min-width: 1001px)').matches) {
            const videoElmt = document.createElement('video')
            const videoSrc = document.createElement('source')

            // config
            videoSrc.type = $(videoContainer).data('banner-video-type')
            videoSrc.src = $(videoContainer).data('banner-video-src')
            videoElmt.muted = true
            videoElmt.loop = true

            videoElmt.appendChild(videoSrc)
            $(videoContainer).append(videoElmt)
            videoElmt.play()
        } else if ($(swiperContainer).length > 0) {
            const options = {
                loop: true,
                autoplay: {
                    delay: 6000,
                    disableOnInteraction: true,
                },
                parallax: true,
                speed: 1000,
                pagination: {
                    el: '.js-banner-slider-pagination',
                    clickable: true,
                    renderBullet: (index, className) => {
                        if (Array.isArray(slideTitles) && slideTitles.length > 0) {
                            return `<div class="${className}"><span class="banner-pagination-label">0${index + 1}. ${slideTitles[index]}</span></div>`
                        }

                        return ''
                    },
                },
                grabCursor: true,
                on: {
                    init: () => {},

                    slideChangeTransitionEnd: () => {},
                },
            }

            // insert navigation / pagination
            $(`${swiperContainer}> *`)
                .wrapAll('<div class="swiper-wrapper"></div>')
                .addClass('swiper-slide')

            const swiper = await Swiper.create(swiperContainer, options)

            if ($('[data-banner-slider] > .swiper-wrapper').length) {
                $triggerEl.on('mouseenter', () => {
                    const $activeBullet = $(
                        '.js-banner-slider-pagination > .swiper-pagination-bullet-active',
                    )
                    swiper.autoplay.stop()
                    $header.addClass('swiper-paused')
                    $activeBullet.css({
                        'animation-play-state': 'paused',
                    })
                })

                $triggerEl.on('mouseleave', () => {
                    const $activeBullet = $(
                        '.js-banner-slider-pagination > .swiper-pagination-bullet-active',
                    )
                    swiper.autoplay.start()
                    $header.removeClass('swiper-paused')
                    $activeBullet.removeClass('swiper-pagination-bullet-active')
                    setTimeout(() => {
                        $activeBullet.addClass('swiper-pagination-bullet-active')
                    }, 1000)
                })
            }
        }
    }
}
