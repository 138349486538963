export default class GtranslateAction {
    constructor() {
        GtranslateAction.translate()
    }

    static translate() {
        $('.tools__item--langs__list a, .menu__tools__item--langs__list a').on('click', (e) => {
            updateCurrentLanguageTopbar()
        })

        const updateCurrentLanguageTopbar = () => {
            window.setTimeout(() => {
                let current_language = $('html').attr('lang').replace(/-.*/gi, '')
                current_language = current_language === 'auto' ? 'fr' : current_language
                $('.tools__item--langs--current').text(current_language)
                $('.menu__tools__item--langs--current').text(current_language)
                $('.glink').each(function () {
                    if ($(this).text() === current_language.toUpperCase()) {
                        $(this).css('display', 'none')
                    } else {
                        $(this).css('display', 'block')
                    }
                })
            }, 250)
        }

        $(window).on('load', () => {
            setTimeout(() => {
                updateCurrentLanguageTopbar()
            }, 250)
        })

        $('.js-menu-tools-langs').on('click', () => {
            $('.js-menu-tools-langs-list').toggleClass('is-active')
        })
    }
}
