import MenuComponent from './menu.component'

export default class NavbarComponent {
    constructor() {
        this.sticky()
        this.burger()
    }

    /**
     * Change logo path
     * @param color
     */
    static #changeLogoColor(color) {
        const logo = $('[data-id="wpiris-logo"]')
        let logoBanner
        let logoClassic

        logoBanner = IRISCollectionCustomer.imageLogoBanner
        logoClassic = IRISCollectionCustomer.imageLogo

        if (color === 'banner') {
            logo.attr('src', logoBanner)
        } else if (color === 'classic') {
            logo.attr('src', logoClassic)
        }
    }
    static $body = $('body')

    sticky() {
        let lastScrollTop = 0
        $(window).on('load scroll', function () {
            const offset = 120
            const offsetOnScroll = 120
            const navbar = $('[data-id="wpiris-navbar"]')
            const scrollTop = $(this).scrollTop()
            let full = false

            if (!$('body').hasClass('menu-open')) {
                //Hidden comportment
                if (scrollTop >= lastScrollTop && scrollTop > navbar.outerHeight()) {
                    NavbarComponent.$body.addClass('header-is-hidden')
                } else {
                    NavbarComponent.$body.removeClass('header-is-hidden')
                }

                // Detect if header is on banner/video mode
                if ($('body.has-banner').length > 0) {
                    full = true
                }

                lastScrollTop = scrollTop

                if (scrollTop > offset) {
                    navbar.removeClass('navbar--ontop')
                    navbar.addClass('navbar--onscroll')
                    if (full === true) {
                        NavbarComponent.#changeLogoColor('classic')
                    }
                } else if (scrollTop < offsetOnScroll) {
                    navbar.removeClass('navbar--onscroll')
                    navbar.addClass('navbar--ontop')
                    if (full === true) {
                        NavbarComponent.#changeLogoColor('banner')
                    }
                }
            }
        })
    }

    burger() {
        const trigger = '[data-trigger="wpiris-menu-toggle"]'
        const more = '[data-trigger="wpiris-menu-more"]'
        const prev = '[data-trigger="wpiris-menu-prev"]'
        const submenu = '[data-trigger="wpiris-menu-submenu"]'

        //Open/Close menu
        $(trigger).on('click', () => {
            NavbarComponent.$body.toggleClass('menu-open')

            //Change logo
            if (NavbarComponent.$body.hasClass('menu-open')) {
                NavbarComponent.#changeLogoColor('classic')
            } else {
                MenuComponent.resetMenu()
                if ($('.navbar--ontop').length > 0 && $('body.has-banner').length > 0) {
                    NavbarComponent.#changeLogoColor('banner')
                }
            }

            //Close ecobar when menu is open
            if (NavbarComponent.$body.hasClass('menu-open') && $('.navbar--ontop').length) {
                $('.eco-bar').addClass('eco-bar--hidden')
            }
        })

        //Open/Close submenu
        $(more).on('click', function () {
            $(this).next(submenu).toggleClass('submenu-open')
        })

        $(prev).on('click', (e) => {
            e.preventDefault()
            $(submenu).removeClass('submenu-open')
        })

        $('.menu-open-filter').on('click', () => {
            NavbarComponent.$body.removeClass('menu-open')
            MenuComponent.resetMenu()

            if ($('.navbar--ontop').length > 0 && $('body.has-banner').length > 0) {
                NavbarComponent.#changeLogoColor('banner')
            }
        })
    }
}
